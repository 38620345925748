import React, { useState } from "react";
import "./SortableTable.css";
import { Form, Popconfirm, Table, Typography } from "antd";
import { arrayMoveImmutable } from "array-move";

import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import DropdownSelect from "../DropdownSelect/DropdownSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconsList from "../../iconsList";

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: "grab",
      color: "#999",
    }}
  />
));
const EditableCell = ({ editing, record, dataIndex, children, ...restProps }) => {
  const icons = iconsList.map((icon) => {
    return {
      id: icon,
      name: icon,
    };
  });
  return (
    <td {...restProps}>
      {dataIndex === "icon" ? (
        editing ? (
          <DropdownSelect
            name={dataIndex}
            data={icons}
            value={icons.find((icon) => icon.name === record.icon)}
            isIcon={true}
            rules={[{ required: true, message: "Por favor, elija un icono" }]}
          />
        ) : record ? (
          <FontAwesomeIcon icon={record.icon} />
        ) : (
          children
        )
      ) : (
        children
      )}
    </td>
  );
};
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const SortableTable = ({ id, columns, data, pagination = false, onFinish, setIsFinishDisabled }) => {
  const [dataSource, setDataSource] = useState(data);
  const [editingIndex, setEditingIndex] = useState("");
  const [form] = Form.useForm();
  const isEditing = (record) => record.index === editingIndex;
  const edit = (record) => {
    setIsFinishDisabled(true);
    form.setFieldsValue({
      ...record,
    });
    setEditingIndex(record.index);
  };
  const cancel = () => {
    setEditingIndex("");
    setIsFinishDisabled(false);
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataSource(newData);
        setEditingIndex("");
      } else {
        newData.push(row);
        setDataSource(newData);
        setEditingIndex("");
      }
    } catch (errInfo) {
      console.error("Validate Failed:", errInfo);
    } finally {
      setIsFinishDisabled(false);
    }
  };
  const sortedColumns = [
    {
      title: "Orden",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    ...columns,
    {
      title: "Acciones",
      dataIndex: "actions",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Guardar
            </Typography.Link>
            <Popconfirm title="Deseas cancelar?" onConfirm={cancel} cancelText="No" okText="Si">
              <a>Cancelar</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingIndex !== ""} onClick={() => edit(record)}>
            Editar Icono
          </Typography.Link>
        );
      },
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter((el) => !!el);
      setDataSource(newData);
    }
  };
  const DraggableContainer = (props) => (
    <SortableBody useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );
  const DraggableBodyRow = ({ ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex((x) => x.index === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };
  const mergedColumns = sortedColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleFinish = () => {
    onFinish(dataSource);
  };

  return (
    <Form id={id} form={form} onFinish={handleFinish} preserve={false}>
      <Table
        pagination={pagination}
        dataSource={dataSource}
        columns={mergedColumns}
        rowKey="index"
        bordered
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
            cell: EditableCell,
          },
        }}
      />
    </Form>
  );
};
export default SortableTable;
