import { add, del, edit, getAll } from "./commonRequests";

const ATTRIBUTE_PATH = "/attribute";

export const addAttribute = (attribute) => {
  return add(ATTRIBUTE_PATH, attribute);
};

export const deleteAttribute = (id) => {
  return del(ATTRIBUTE_PATH, id);
};

export const getAllAttributes = () => {
  return getAll(ATTRIBUTE_PATH);
};

export const getAllAttributesView = () => {
  return getAll(`${ATTRIBUTE_PATH}_view`);
};

export const editAttribute = (attribute) => {
  return edit(ATTRIBUTE_PATH, attribute);
};
