import React from "react";
import { Select } from "antd";
import FormItem from "antd/lib/form/FormItem";

import "./DropdownSelect.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DropdownSelect({
  data,
  label,
  className = "",
  name,
  placeholder,
  rules,
  onChange,
  mode = null,
  defaultValue,
  value,
  fieldKey = null,
  disabled = false,
  isIcon,
}) {
  const { Option } = Select;
  return (
    <FormItem className={`${className}`} name={name} label={label} rules={rules} fieldKey={fieldKey}>
      <Select
        data-testid="dropdown-select"
        className="dropdown-select"
        mode={mode}
        showSearch={true}
        placeholder={placeholder}
        onChange={onChange}
        defaultValue={defaultValue}
        disabled={disabled}
        value={value}
        filterOption={(input, option) => {
          if (isIcon) {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }
          return option.children.toLowerCase().includes(input.toLowerCase());
        }}
      >
        {data
          ? data.map((value, index) => {
              return (
                <Option key={index} value={value.id}>
                  {isIcon ? (
                    <div>
                      <FontAwesomeIcon icon={value.name} /> {value.name}
                    </div>
                  ) : (
                    value.name
                  )}
                </Option>
              );
            })
          : null}
      </Select>
    </FormItem>
  );
}
