import firebase from "firebase/compat/app";
import * as firebaseConfig from "../private/firebaseConfig";
import "firebase/compat/auth";

// Initialize Firebase
firebase.initializeApp(firebaseConfig.default);

export const auth = firebase.auth();

export const signOut = async () => {
  auth.signOut().finally(() => sessionStorage.removeItem("token"));
};

auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = async () => await auth.signInWithPopup(provider);
