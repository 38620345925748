import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { DropdownSelect, Input } from "..";
import { ATTRIBUTETYPE, FIELDS, NAME, TAXPAYERTYPEIDS } from "../../constants";
import { getAllTaxpayerTypes } from "../../services/taxpayerType";
import { getTaxpayerTypesSelected } from "../../services/attributeType";

export default function AttributeTypeForm({
  id,
  onFinish,
  item: attributeType = null,
  form,
}) {
  const [taxpayerTypes, setTaxpayerTypes] = useState([]);

  useEffect(() => {
    let isUnmounted = false;
    getAllTaxpayerTypes().then(
      (resTaxpayerTypes) => !isUnmounted && setTaxpayerTypes(resTaxpayerTypes)
    );
    if (attributeType) {
      getTaxpayerTypesSelected(attributeType.id).then(
        (resTaxpayerTypes) =>
          !isUnmounted &&
          form.setFieldsValue(
            formatAttributeTypeToForm(attributeType, resTaxpayerTypes)
          )
      );
    }
    return () => {
      isUnmounted = true;
    };
  }, []);

  const formatAttributeTypeToForm = (attributeType, taxpayerTypes) => {
    return {
      [ATTRIBUTETYPE]: attributeType[NAME],
      [TAXPAYERTYPEIDS]: taxpayerTypes,
    };
  };

  const handleFinish = (attributeTypeFields) => {
    onFinish(formatAttributeTypeToAPI(attributeTypeFields));
    if (!form) {
      return;
    }
    form.resetFields();
  };

  const formatAttributeTypeToAPI = (attributeTypeFields) => {
    return {
      [ATTRIBUTETYPE]: {
        [FIELDS]: {
          [ATTRIBUTETYPE]: attributeTypeFields[ATTRIBUTETYPE],
        },
      },
      [TAXPAYERTYPEIDS]: attributeTypeFields[TAXPAYERTYPEIDS],
    };
  };

  return (
    <Form
      id={id}
      form={form}
      onFinish={handleFinish}
      validateMessages={{ required: "Por favor complete: ${label}" }}
    >
      <Input
        name={ATTRIBUTETYPE}
        label="Nombre de tipo de atributo"
        placeholder="Ingrese..."
        rules={[
          {
            required: true,
          },
        ]}
      />
      <DropdownSelect
        name={TAXPAYERTYPEIDS}
        label="Tipo de persona"
        rules={[
          {
            required: true,
          },
        ]}
        mode="multiple"
        data={taxpayerTypes}
        placeholder="Elija..."
      />
    </Form>
  );
}
