import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";

import "./FoldersScreen.css";

import { List as FolderList, FolderTypeForm, FolderForm, SortableTable, Spinner } from "../../components";

import { addFolderType, deleteFolderType, editFolderType, getAllFoldersTypes } from "../../services/folderType";

import { addFolder, deleteFolder, editFolder } from "../../services/folder";

import { FIELDS, ID } from "../../constants";
import { addFolderCriteria, getFolderCriteria } from "../../services/folderCriteria";
import { getErrorLoadingListMsg } from "../../utils";

export default function FoldersScreen() {
  const [folderTypes, setFolderTypes] = useState([]);
  const [isFolderTypeModalVisible, setIsFolderTypeModalVisible] = useState(false);
  const [isFolderModalVisible, setIsFolderModalVisible] = useState(false);
  const [FolderTypeIdSelected, setFolderTypeIdSelected] = useState(null);
  const [isFolderCriteriaModalVisible, setIsFolderCriteriaModalVisible] = useState(false);
  const [folderTypesCriteria, setFolderTypesCriteria] = useState([]);
  const [folderTypesCriteriaForTable, setFolderTypesCriteriaForTable] = useState([]);
  const [disableFolderCriteria, setDisableFolderCriteria] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFolderCriteriaLoading, setIsFolderCriteriaLoading] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    let isUnmounted = false;
    loadFolders(isUnmounted);
    return () => {
      isUnmounted = true;
    };
  }, []);

  useEffect(() => {
    setUpFolderCriteria();
  }, [folderTypes]);

  const setUpFolderCriteria = async () => {
    setFolderTypesCriteria([]);
    if (folderTypes.length <= 0) {
      return;
    }
    //Edit this when api is finished
    try {
      const folderTypeCriteriaFromDB = await getFolderCriteria();
      const formattedFolderTypeCriteriaFromDB = formatFolderCriteriaFromDB(folderTypeCriteriaFromDB);
      const foldersNotAddedToCriteria = folderTypes.filter(
        (folderType) =>
          !formattedFolderTypeCriteriaFromDB.find(
            (folderTypeCriteria) => folderTypeCriteria.folderType === folderType.name
          )
      );
      const newFolders = foldersNotAddedToCriteria.map((folderType, index) => {
        return {
          folderType: folderType.name,
          icon: "folder",
          index: [index + formattedFolderTypeCriteriaFromDB.length],
        };
      });
      setFolderTypesCriteria([...formattedFolderTypeCriteriaFromDB, ...newFolders]);
    } catch (error) {
      console.error(error);
    }
  };

  const formatFolderCriteriaFromDB = (folderTypeCriteriaFromDB) => {
    return folderTypeCriteriaFromDB.map((folderCriteria) => folderCriteria[FIELDS]);
  };

  const loadFolders = (isUnmounted) => {
    setIsLoading(true);
    getAllFoldersTypes()
      .then((resFolderTypes) => {
        const folderTypeFormatted = resFolderTypes.map((data) => {
          return {
            name: data.name,
            id: data.id,
            subItems: data.folders,
          };
        });
        !isUnmounted && setFolderTypes(folderTypeFormatted);
      })
      .catch(
        (error) =>
          !isUnmounted &&
          modal.error({
            title: "Error",
            content: getErrorLoadingListMsg("carpetas", error),
          })
      )
      .finally(() => !isUnmounted && setIsLoading(false));
  };

  //FolderTypes
  const handleAddFolderType = () => {
    setIsFolderTypeModalVisible(true);
  };

  const handleFinishFolderType = (folderType) => {
    addFolderType(folderType)
      .then(() => {
        setIsFolderTypeModalVisible(false);
        loadFolders();
      })
      .catch((err) => console.error(err));
  };

  const handleDeleteFolderType = (id) => {
    deleteFolderType(id)
      .then(() => loadFolders())
      .catch((err) => console.error(err));
  };

  const handleEditFolderType = (folderType, folderTypeId) => {
    const folderTypeFormatted = {
      ...folderType,
      [ID]: folderTypeId,
    };
    editFolderType(folderTypeFormatted).then(() => loadFolders());
  };

  //Folders
  const handleAddFolder = (id) => {
    setFolderTypeIdSelected(id);
    setIsFolderModalVisible(true);
  };

  const handleFinishFolder = (folderWithAttributes) => {
    folderWithAttributes.folder.fields.folderTypeId = FolderTypeIdSelected;
    addFolder(folderWithAttributes).then(() => {
      setIsFolderModalVisible(false);
      loadFolders();
    });
  };

  const handleDeleteFolder = (id) => {
    deleteFolder(id)
      .then(() => loadFolders())
      .catch((err) => console.error(err));
  };

  const handleEditFolder = (folderWithAttributes) => {
    folderWithAttributes.folder.fields.folderTypeId = FolderTypeIdSelected;
    editFolder(folderWithAttributes)
      .then(() => loadFolders())
      .catch((err) => console.error(err));
  };

  const handleFolderTypeCriteria = () => {
    formatFolderCriteriaDataToTable();
    setIsFolderCriteriaModalVisible(true);
  };

  const formatFolderCriteriaDataToTable = () => {
    const folderTypesCriteriaFormatted = folderTypesCriteria.map((folderTypeCriteria) => {
      return {
        key: folderTypeCriteria.index,
        folderType: folderTypeCriteria.folderType,
        icon: folderTypeCriteria.icon,
        index: folderTypeCriteria.index,
      };
    });
    setFolderTypesCriteriaForTable(folderTypesCriteriaFormatted.sort((a, b) => a.index - b.index));
  };

  const formatFolderCriteriaDataToAPI = (folderCriteriaList) => {
    return folderCriteriaList.map((folderCriteria, index) => {
      return {
        [FIELDS]: {
          folderType: folderCriteria.folderType,
          icon: folderCriteria.icon,
          index: index,
        },
      };
    });
  };

  const onFinishFolderCriteria = (folderCriteriaList) => {
    setIsFolderCriteriaLoading(true);
    setDisableFolderCriteria(true);
    const formattedFolderCriteriaList = formatFolderCriteriaDataToAPI(folderCriteriaList);
    addFolderCriteria(formattedFolderCriteriaList)
      .then(() => {
        setDisableFolderCriteria(false);
        setIsFolderCriteriaModalVisible(false);
        setUpFolderCriteria();
      })
      .catch((err) => console.error(err))
      .finally(() => setIsFolderCriteriaLoading(false));
  };

  const toggleDisableFolderCriteria = (isDisable) => {
    setDisableFolderCriteria(isDisable);
  };

  return (
    <div className="folders">
      <div className="content-title">
        <h2>Carpetas</h2>
        <div>
          <Button onClick={handleAddFolderType}>Nuevo Tipo de Carpeta</Button>
          <Button onClick={handleFolderTypeCriteria}>Editar orden de Tipos de carpetas</Button>
        </div>
      </div>
      <Spinner spinning={isLoading}>
        <FolderList
          data={folderTypes}
          handleItemDelete={handleDeleteFolderType}
          handleItemEdit={handleEditFolderType}
          handleSubItemDelete={handleDeleteFolder}
          handleSubItemAdd={handleAddFolder}
          handleSubItemEdit={handleEditFolder}
          ItemForm={FolderTypeForm}
          SubItemForm={FolderForm}
          itemModalTitle={"Editar tipo de carpeta"}
          subItemModalTitle={"Editar carpeta"}
          isEditOnSubItemEnabled={() => true}
        />
      </Spinner>
      <Modal
        title="Añadir Tipo de Carpeta"
        onCancel={() => setIsFolderTypeModalVisible(false)}
        open={isFolderTypeModalVisible}
        footer={[
          <Button form="addFolderTypeForm" key="submit" htmlType="submit">
            Añadir
          </Button>,
        ]}
      >
        <FolderTypeForm id="addFolderTypeForm" onFinish={handleFinishFolderType} />
      </Modal>
      <Modal
        title="Añadir Carpeta"
        onCancel={() => setIsFolderModalVisible(false)}
        open={isFolderModalVisible}
        footer={[
          <Button form="addFolderForm" key="submit" htmlType="submit">
            Añadir
          </Button>,
        ]}
      >
        <FolderForm id="addFolderForm" onFinish={handleFinishFolder} />
      </Modal>
      <Modal
        style={{ width: "max-content", minWidth: "60%" }}
        title="Editar orden de tipo de carpetas"
        onCancel={() => setIsFolderCriteriaModalVisible(false)}
        open={isFolderCriteriaModalVisible}
        destroyOnClose
        footer={[
          <Button disabled={disableFolderCriteria} form="editFolderCriteria" key="submit" htmlType="submit">
            Completar Edición
          </Button>,
        ]}
      >
        <Spinner spinning={isFolderCriteriaLoading}>
          <SortableTable
            id="editFolderCriteria"
            columns={folderCriteriaColumns}
            data={folderTypesCriteriaForTable}
            onFinish={onFinishFolderCriteria}
            setIsFinishDisabled={(isDisable) => toggleDisableFolderCriteria(isDisable)}
          />
        </Spinner>
      </Modal>
      {contextHolder}
    </div>
  );
}
const folderCriteriaColumns = [
  {
    title: "Tipo de carpeta",
    dataIndex: "folderType",
  },
  {
    title: "Icono",
    dataIndex: "icon",
    editable: true,
  },
];
