import { del, edit, getAll } from "./commonRequests";

const USERFOLDER_PATH = "/user_folder";

export const getRequests = () => {
  return getAll(`${USERFOLDER_PATH}_request`);
};

export const deleteUserFolderData = (userFolderDataId) => {
  return del(`${USERFOLDER_PATH}_data`, userFolderDataId);
};

export const deleteUserFolderDataFile = (fileNameData) => {
  return edit(`${USERFOLDER_PATH}_file`, fileNameData);
};