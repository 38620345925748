import React, { useEffect, useState } from "react";
import { StandaloneForm } from "../../components";
import { CONTENT, DATE, FIELDS, NEWS, TITLE } from "../../constants";
import "./NewsScreen.css";
import { addNews, deleteNews, getAllNews } from "../../services/news";
import { formatDate, getErrorLoadingListMsg } from "../../utils";
import { Modal } from "antd";

export default function NewsScreen() {
  const [news, setNews] = useState([]);
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    let isUnmounted = false;
    loadNews(isUnmounted);
    return () => {
      isUnmounted = true;
    };
  }, []);

  const loadNews = (isUnmounted) => {
    getAllNews()
      .then((resNews) => !isUnmounted && setNews(resNews))
      .catch((error) =>
        modal.error({
          title: "Error",
          content: getErrorLoadingListMsg("novedades", error),
        })
      );
  };

  const handleFinish = (newsData) => {
    addNews(formatNewsToAPI(newsData))
      .then(() => loadNews())
      .catch((error) =>
        modal.error({
          title: "Error",
          content: `${error}`,
        })
      );
  };

  const formatNewsToAPI = (newsData) => {
    return {
      [NEWS]: {
        [FIELDS]: {
          [CONTENT]: newsData[CONTENT],
          [TITLE]: newsData[TITLE],
          [DATE]: formatDate(new Date()),
        },
      },
    };
  };

  const onDelete = (id) => {
    deleteNews(id)
      .then(() => loadNews())
      .catch((error) =>
        modal.error({
          title: "Error",
          content: `${error}`,
        })
      );
  };

  return (
    <>
      <StandaloneForm
        formId="postNewsForm"
        onFinish={handleFinish}
        onDelete={onDelete}
        standaloneData={news}
        publicationFormText="Publica una novedad"
        lastPublicationsText="Últimas novedades publicadas"
      />
      {contextHolder}
    </>
  );
}
