import React, { useEffect, useState } from "react";
import { Button, Form, Tabs } from "antd";
import { Input, DropdownSelect } from "..";
import {
  ATTRIBUTEIDS,
  ATTRIBUTES,
  FIELDS,
  ID,
  IDENTIFICATION,
  MAIL,
  NAME,
  NAME_BUSINESSNAME,
  TAXPAYERTYPEID,
  USER,
  USERINFO,
} from "../../constants";
import { getAllTaxpayerTypes } from "../../services/taxpayerType";
import { getAllAttributesTypesView } from "../../services/attributeType";

const { TabPane } = Tabs;

export default function UserForm({ id, onFinish, user = null, submitForm }) {
  const [taxpayerTypes, setTaxpayerTypes] = useState([]);
  const [attributeTypes, setAttributeTypes] = useState([]);
  const [attributeTypesToSelect, setAttributeTypesToSelect] = useState([]);
  const [activeKey, setActiveKey] = useState("1");
  const [form] = Form.useForm();

  useEffect(() => {
    let isUnmounted = false;
    getAllTaxpayerTypes()
      .then(
        (resTaxpayerTypes) => !isUnmounted && setTaxpayerTypes(resTaxpayerTypes)
      )
      .then(() =>
        getAllAttributesTypesView().then((resAttributeTypes) => {
          !isUnmounted && setAttributeTypes(resAttributeTypes);
        })
      );
    return () => (isUnmounted = true);
  }, []);

  useEffect(() => {
    if (user && attributeTypes.length > 0) {
      filterAttributeTypes(user[USERINFO].taxpayerTypeId);
      form.setFieldsValue(formatUserToForm(user));
    }
  }, [attributeTypes]);

  const formatUserToForm = (user) => {
    return {
      [USER]: {
        ...user[USERINFO],
      },
      [ATTRIBUTES]: mapAttributesToForm(user[ATTRIBUTES]),
    };
  };

  const mapAttributesToForm = (attributes) => {
    return attributes.reduce((attributesEntries, attribute) => {
      const attributeType = attributeTypes.find(
        (attributeType) =>
          attributeType[ATTRIBUTES].filter(
            (attributeToCompare) => attribute[ID] == attributeToCompare[ID]
          ).length > 0
      );
      if ([attributeType[NAME]] in attributesEntries) {
        attributesEntries[attributeType[NAME]].push(attribute[ID]);
        return attributesEntries;
      }
      return {
        ...attributesEntries,
        [attributeType[NAME]]: [attribute[ID]],
      };
    }, {});
  };

  const handleFinish = (userData) => {
    setActiveKey("1");
    onFinish(formatUserToAPI(userData));
  };

  const formatUserToAPI = (userData) => {
    const userDataFormatted = {
      [USER]: {
        [FIELDS]: {
          ...userData[USER],
        },
      },
      [ATTRIBUTEIDS]: mapAttributesToAPI(userData[ATTRIBUTES]),
    };
    if (user) {
      userDataFormatted[USER][ID] = user[USERINFO][ID];
    }
    return userDataFormatted;
  };

  const mapAttributesToAPI = (attributes) => {
    let attributesIds = [];
    Object.entries(attributes).forEach(([, values]) => {
      Array.prototype.push.apply(attributesIds, values);
    });
    return attributesIds;
  };

  const filterAttributeTypes = (taxpayerTypeIdSelected) => {
    let filteredAttributeTypes = attributeTypes.filter((attributeType) =>
      attributeType.taxpayerTypeIds.includes(taxpayerTypeIdSelected)
    );
    setAttributeTypesToSelect(filteredAttributeTypes);
  };

  return (
    <Form
      data-testid="user-form"
      id={id}
      form={form}
      validateMessages={{ required: "Por favor complete: ${label}" }}
      layout="vertical"
      onFinish={handleFinish}
      preserve={false}
    >
      <Tabs activeKey={activeKey} onTabClick={setActiveKey}>
        <TabPane tab="Informacion de usuario" key="1">
          <Input
            name={[USER, NAME_BUSINESSNAME]}
            label="Nombre/Razón Social"
            placeholder="Ingrese..."
            rules={[
              {
                required: true,
              },
            ]}
          />
          <Input
            name={[USER, IDENTIFICATION]}
            label="Identificacion"
            placeholder="Ingrese..."
            rules={[
              {
                required: true,
              },
            ]}
          />
          <Input
            name={[USER, MAIL]}
            label="Mail"
            placeholder="Ingrese..."
            rules={[
              {
                required: true,
                type: "email",
              },
            ]}
          />
          <DropdownSelect
            name={[USER, TAXPAYERTYPEID]}
            label="Tipo de persona"
            placeholder="Seleccione..."
            onChange={filterAttributeTypes}
            data={taxpayerTypes}
            rules={[
              {
                required: true,
              },
            ]}
          />
          <Button style={{ float: "right" }} onClick={() => setActiveKey("2")}>
            Siguiente
          </Button>
        </TabPane>
        <TabPane tab="Atributos de usuario" key="2">
          {attributeTypesToSelect.map((attributeType, index) => {
            return (
              <DropdownSelect
                key={index}
                name={[ATTRIBUTES, attributeType.name]}
                label={attributeType.name}
                placeholder="Seleccione..."
                data={attributeType.attributes}
                rules={[
                  {
                    required: true,
                  },
                ]}
                mode="multiple"
              />
            );
          })}
          <div>
            {submitForm}
            <Button
              style={{ float: "right" }}
              onClick={() => setActiveKey("1")}
            >
              Atras
            </Button>
          </div>
        </TabPane>
      </Tabs>
    </Form>
  );
}
