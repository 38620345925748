import React, { useEffect, useState } from "react";
import { StandaloneForm } from "../../components/index";
import { CONTENT, DATE, FIELDS, TITLE, USEFULLINK } from "../../constants";
import { addUsefulLink, deleteUsefulLink, getAllUsefulLink } from "../../services/usefulLinks";
import { formatDate } from "../../utils";

export default function UsefulLinksScreen() {
  const [usefulLinks, setUsefulLinks] = useState([]);

  useEffect(() => {
    let isUnmounted = false;
    getAllUsefulLink().then((resData) => !isUnmounted && setUsefulLinks(resData));
    return () => {
      isUnmounted = true;
    };
  }, []);

  const onDelete = (id) => {
    deleteUsefulLink(id).then(() => {
      loadUsefulLinks();
    });
  };

  const onFinish = (data) => {
    addUsefulLink(formatUsefulLinkToAPI(data)).then(() => {
      loadUsefulLinks();
    });
  };

  const formatUsefulLinkToAPI = (usefulLinkData) => {
    return {
      [USEFULLINK]: {
        [FIELDS]: {
          [CONTENT]: usefulLinkData[CONTENT],
          [TITLE]: usefulLinkData[TITLE],
          [DATE]: formatDate(new Date()),
        },
      },
    };
  };

  const loadUsefulLinks = () => {
    getAllUsefulLink().then((resData) => setUsefulLinks(resData));
  };

  return (
    <StandaloneForm
      formId="usefulLinkForm"
      standaloneData={usefulLinks}
      onDelete={onDelete}
      publicationFormText="Publica una guía o Link de interés"
      lastPublicationsText="Ultimas guías o Links de interés publicadas"
      onFinish={onFinish}
    />
  );
}
