import React from "react";
import "./Header.css";

export default function Header({ children }) {
  return (
    <div data-testid="header" className="header">
      {children}
    </div>
  );
}
