import React from "react";

export default function HorizontalLine({ color }) {
  return (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 1,
        border: "none",
      }}
    />
  );
}
