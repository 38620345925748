import React, { useState } from "react";
import {
  UsersScreen,
  AttributesScreen,
  LoginScreen,
  FoldersScreen,
  NewsScreen,
  RequestsScreen,
  RegisterRequestScreen,
} from "./screens";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import { Layout, Menu, Image, Modal, Button, Input } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  BookOutlined,
  PoweroffOutlined,
  FolderOutlined,
  BellOutlined,
  CarryOutOutlined,
  QuestionCircleOutlined,
  LockOutlined,
  UnlockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import { Footer, Header } from "./components";

import "./App.css";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { signOut } from "./services/firebase";
import UsefulLinksScreen from "./screens/UsefulLinksScreen/UsefulLinksScreen";
import { UNLOCK_FOLDERS_ATTRIBUTES_PWD } from "./constants";

const { Sider } = Layout;

function App() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  // Quick implementation of locking some features to same account users,
  // Can be better implemented
  const [isLocked, setIsLocked] = useState(true);
  const [unlockModalVisible, setUnlockModalVisible] = useState(false);
  const [password, setPassword] = useState("");

  const toggleSider = () => {
    setIsCollapsed(!isCollapsed);
  };

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const unlockFolderAttributesScreens = (pwd) => {
    if (pwd !== UNLOCK_FOLDERS_ATTRIBUTES_PWD) {
      alert("Contraseña incorrecta");
      return;
    }
    setIsLocked(false);
    setUnlockModalVisible(false);
  };

  const items = [
    getItem(<Link to="/home/users">Usuarios</Link>, 1, <UserOutlined />, null), //
    !isLocked && getItem(<Link to="/home/attributes">Atributos</Link>, 2, <BookOutlined />, null), //
    !isLocked && getItem(<Link to="/home/folders">Carpetas</Link>, 3, <FolderOutlined />, null), //
    getItem(<Link to="/home/news">Novedades</Link>, 4, <BellOutlined />, null), //
    getItem(<Link to="/home/guides">Guías y Links de interés</Link>, 5, <QuestionCircleOutlined />, null), //
    getItem(<Link to="/home/requests">Solicitudes</Link>, 6, <CarryOutOutlined />, null), //
    getItem(<Link to="/home/register_requests">Solicitudes de Registro</Link>, 7, <ContactsOutlined />, null), //
    getItem(
      <Link to="/login" onClick={() => signOut() && <Redirect to="/login" />}>
        Cerrar Sesion
      </Link>,
      8,
      <PoweroffOutlined />,
      null
    ), //
  ];

  return (
    <div className="screen">
      <Router>
        <Switch>
          <Route path="/login" component={LoginScreen} />
          <PrivateRoute default path="/home">
            <Layout>
              <Sider trigger={null} collapsible collapsed={isCollapsed}>
                <div className="logo">
                  {!isCollapsed ? (
                    <Image src="/logo.png" width={75} preview={false} />
                  ) : (
                    <Image src="/logo.png" width={75} preview={false} />
                  )}
                </div>
                <Menu items={items} theme="dark" mode="inline" defaultSelectedKeys={[1]} />
              </Sider>
              <Layout>
                <Header>
                  {React.createElement(isCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: "anticon",
                    onClick: toggleSider,
                  })}
                </Header>
                <Switch>
                  <PrivateRoute path="/home/users" component={UsersScreen} />
                  {!isLocked && (
                    <div>
                      <PrivateRoute path="/home/attributes" component={AttributesScreen} />
                      <PrivateRoute path="/home/folders" component={FoldersScreen} />
                    </div>
                  )}
                  <PrivateRoute path="/home/news" component={NewsScreen} />
                  <PrivateRoute path="/home/requests" component={RequestsScreen} />
                  <PrivateRoute path="/home/guides" component={UsefulLinksScreen} />
                  <PrivateRoute path="/home/register_requests" component={RegisterRequestScreen} />
                  <Redirect to="/home/users" />
                </Switch>
                <Footer>
                  <>
                    {React.createElement(isLocked ? LockOutlined : UnlockOutlined, {
                      className: "anticon",
                      onClick: () => setUnlockModalVisible(true),
                    })}
                    TaxApp Team
                  </>
                </Footer>
              </Layout>
            </Layout>
          </PrivateRoute>
          <Redirect to="/login" />
        </Switch>
      </Router>
      <Modal
        onCancel={() => setUnlockModalVisible(false)}
        destroyOnClose
        title="Desbloquear carpetas y atributos"
        open={unlockModalVisible}
        footer={[
          <Button key="submit" onClick={() => unlockFolderAttributesScreens(password)}>
            Desbloquear
          </Button>,
        ]}
      >
        <Input.Password
          placeholder="Ingrese.."
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Modal>
    </div>
  );
}

export default App;
