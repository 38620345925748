import adminInstance from "./axiosInstance";
import { API_URL } from "../constants";

export const add = (path, data, options) => {
  return new Promise((resolve, reject) => {
    adminInstance
      .post(`${API_URL}${path}`, data, { options })
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err.response ? err.response.data : err);
      });
  });
};

export const getById = (path, id) => {
  return new Promise((resolve, reject) => {
    adminInstance
      .get(`${API_URL}${path}/${id}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : err));
  });
};

export const getByField = (path, field) => {
  return new Promise((resolve, reject) =>
    adminInstance
      .post(`${API_URL}${path}`, field)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response ? err.response.data : err);
      })
  );
};

export const getAll = (path) => {
  return new Promise((resolve, reject) => {
    adminInstance
      .get(`${API_URL}${path}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : err));
  });
};

export const edit = (path, data) => {
  return new Promise((resolve, reject) => {
    adminInstance
      .patch(`${API_URL}${path}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err.response ? err.response.data : err));
  });
};

export const del = (path, id) => {
  return new Promise((resolve, reject) => {
    adminInstance
      .delete(`${API_URL}${path}/${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err.response ? err.response.data : err));
  });
};

export const getRelation = (path, id, relationPath) => {
  return new Promise((resolve, reject) => {
    adminInstance
      .get(`${API_URL}${path}/${id}/${relationPath}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : err));
  });
};
