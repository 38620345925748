import { Button, Card, List, Modal } from "antd";
import React, { useEffect, useState } from "react";
import "./RegisterRequestScreen.css";
import { getErrorLoadingListMsg } from "../../utils";
import { Spinner } from "../../components";
import { deleteRegisterRequest, getAllRegisterRequests } from "../../services/registerRequest";

export default function RegisterRequestScreen() {
  const [registerRequestList, setRegisterRequestList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    let isUnmounted = false;
    loadRegisterRequest(isUnmounted);
    return () => {
      isUnmounted = true;
    };
  }, []);

  const loadRegisterRequest = (isUnmounted) => {
    setIsLoading(true);
    getAllRegisterRequests()
      .then(async (registerRequests) => {
        if (isUnmounted || !registerRequests) {
          return;
        }
        let processsedRegisterRequest = [];
        for (let registerRequest of registerRequests) {
          processsedRegisterRequest.push({
            id: registerRequest.id,
            name: registerRequest.fields.name,
            email: registerRequest.fields.mail,
            message: registerRequest.fields.message,
          });
        }
        setRegisterRequestList(processsedRegisterRequest);
      })
      .catch(
        (error) =>
          !isUnmounted &&
          modal.error({ title: "Error", content: getErrorLoadingListMsg("solicitudes de registro", error) })
      )
      .finally(() => !isUnmounted && setIsLoading(false));
  };

  const finishRequest = (registerRequestId) => {
    deleteRegisterRequest(registerRequestId)
      .then(() => modal.success({ title: "Finalizado", content: "La solicitud ha sido marcada como finalizada" }))
      .catch((error) => modal.error({ title: "Error", content: "algo ha fallado: " + error }))
      .finally(() => loadRegisterRequest());
  };

  return (
    <div className="default-screen">
      <Card title={"Solicitudes de registro pendientes"} className="default-list">
        <List>
          <Spinner spinning={isLoading}>
            {registerRequestList &&
              registerRequestList.map((registerRequest, index) => (
                <List.Item key={index}>
                  <List.Item.Meta
                    title={
                      <div className="default-title">
                        <h3>Nombre: {registerRequest.name}</h3>
                        <h4>Email: {registerRequest.email}</h4>
                        <Button onClick={() => finishRequest(registerRequest.id)}>
                          Finalizar solicitud de registro
                        </Button>
                      </div>
                    }
                    description={
                      <div>
                        <p>{registerRequest.message}</p>
                      </div>
                    }
                  />
                </List.Item>
              ))}
          </Spinner>
        </List>
      </Card>
      {contextHolder}
    </div>
  );
}
