import React, { useState } from "react";
import { Modal, List, Card, Button, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ListItem } from "..";

import "./List.css";
import { useForm } from "antd/lib/form/Form";

export default function ItemsList({
  data,
  handleItemEdit,
  handleItemDelete,
  handleSubItemAdd,
  handleSubItemEdit,
  handleSubItemDelete,
  ItemForm,
  SubItemForm,
  subItemModalTitle,
  itemModalTitle,
  isEditOnSubItemEnabled,
}) {
  const [itemToEdit, setItemToEdit] = useState(null);
  const [subItemToEdit, setSubItemToEdit] = useState(null);
  const [isItemEditModalVisible, setIsItemEditModalVisible] = useState(false);
  const [isSubItemEditModalVisible, setIsSubItemEditModalVisible] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isSubItemEditable, setIsSubItemEditable] = useState(false);

  const [formItem] = useForm();
  const [formSubItem] = useForm();

  const checkSubItemEditable = async (subItemId) => {
    const resultIsEditable = await isEditOnSubItemEnabled(subItemId);
    setIsSubItemEditable(resultIsEditable);
  };

  const onItemEdit = (item) => {
    setItemToEdit(item);
    setIsItemEditModalVisible(true);
  };

  const onFinishItemEdit = (item) => {
    handleItemEdit(item, itemToEdit.id);
    setIsItemEditModalVisible(false);
  };

  const onSubItemEdit = (subItem, itemId) => {
    setSubItemToEdit(subItem);
    setSelectedItemId(itemId);
    setIsSubItemEditModalVisible(true);
  };

  const onFinishSubItemEdit = (subItem) => {
    handleSubItemEdit(subItem, selectedItemId);
    setIsSubItemEditModalVisible(false);
  };

  return (
    <div className="list">
      <List
        grid={{
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 2,
          xxl: 2,
          gutter: 16,
        }}
        dataSource={data}
        locale={{ emptyText: <div className="empty-list" /> }}
        renderItem={(item, index) => (
          <List.Item key={index}>
            <Card
              title={item.name}
              extra={[
                <Button key={0} onClick={() => onItemEdit(item)}>
                  <EditOutlined />
                </Button>,
                <Popconfirm
                  key={1}
                  title="Deseas eliminar?"
                  onConfirm={() => handleItemDelete(item.id)}
                  cancelText="No"
                  okText="Si"
                >
                  <Button>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>,
                <Button key={2} onClick={() => handleSubItemAdd(item.id)}>
                  <PlusOutlined />
                </Button>,
              ]}
            >
              {item.subItems &&
                item.subItems.map((subItem, index) => {
                  {
                    checkSubItemEditable(subItem.id);
                  }
                  return (
                    <ListItem
                      key={index}
                      subItem={subItem}
                      isEditEnabled={isSubItemEditable}
                      handleEdit={onSubItemEdit}
                      handleDelete={handleSubItemDelete}
                      itemId={item.id}
                    />
                  );
                })}
            </Card>
          </List.Item>
        )}
      ></List>
      <Modal
        title={itemModalTitle}
        destroyOnClose
        onCancel={() => {
          setIsItemEditModalVisible(false);
          formItem.resetFields();
        }}
        open={isItemEditModalVisible}
        footer={[
          <Button form="editItemForm" key="submit" htmlType="submit">
            Editar
          </Button>,
        ]}
      >
        <ItemForm id="editItemForm" onFinish={onFinishItemEdit} item={itemToEdit} form={formItem} />
      </Modal>
      <Modal
        title={subItemModalTitle}
        destroyOnClose
        onCancel={() => {
          setIsSubItemEditModalVisible(false);
          formSubItem.resetFields();
        }}
        open={isSubItemEditModalVisible}
        footer={[
          <Button form="editSubItemForm" key="submit" htmlType="submit">
            Editar
          </Button>,
        ]}
      >
        <SubItemForm id="editSubItemForm" onFinish={onFinishSubItemEdit} subItem={subItemToEdit} form={formSubItem} />
      </Modal>
    </div>
  );
}
