import { add, del, edit, getAll, getById } from "./commonRequests";

const USER_PATH = "/user";

export const addUser = (user) => {
  return add(USER_PATH, user);
};

export const getUser = (userId) => {
  return getById(USER_PATH, userId);
};

export const getAllUsers = () => {
  return getAll(USER_PATH);
};

export const editUser = (user) => {
  return edit(USER_PATH, user);
};

export const deleteUser = (id) => {
  return del(USER_PATH, id);
};

export const getAllUsersView = () => {
  return getAll(`${USER_PATH}_view`);
};

export const getUserFolderData = (userId, folderId, period) => {
  if (period) {
    return getAll(`${USER_PATH}/folder_data/${userId}/${folderId}?period=${period}`);
  }
  return getAll(`${USER_PATH}/folder_data/${userId}/${folderId}`);
};

export const sendFolderDataOfUser = (userId, folderId, period, data) => {
  if (period) {
    return add(`${USER_PATH}/folder_data/${userId}/${folderId}?period=${period}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  return add(`${USER_PATH}/folder_data/${userId}/${folderId}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const sendFolderDataOfUserByBody = (userId, folderId, period, data) => {
  if (period) {
    return add(`${USER_PATH}/folder_data/${userId}/${folderId}?period=${period}`, data);
  }
  return add(`${USER_PATH}/folder_data/${userId}/${folderId}`, data);
};
