import { add, getAll } from "./commonRequests";

const FOLDERCRITERIA_PATH = "/folder_criteria";

export const addFolderCriteria = (folderCriteria) => {
  return add(`${FOLDERCRITERIA_PATH}`, folderCriteria);
};

export const getFolderCriteria = () => {
  return getAll(`${FOLDERCRITERIA_PATH}`);
};
