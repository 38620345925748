import { add, getAll, del, edit } from "./commonRequests";

const FOLDERTYPES_PATH = "/folder_type";

export const addFolderType = (folderType) => {
  return add(FOLDERTYPES_PATH, folderType);
};

export const getAllFoldersTypes = () => {
  return getAll(`${FOLDERTYPES_PATH}_view`);
};

export const deleteFolderType = (id) => {
  return del(FOLDERTYPES_PATH, id);
};

export const editFolderType = (folderType) => {
  return edit(FOLDERTYPES_PATH, folderType);
};
