import { usePapaParse } from "react-papaparse";
import { parseColumns } from "../utils";

export const getTableData = (folder, tableNumber) => {
  const { readRemoteFile } = usePapaParse();
  return new Promise((resolve, reject) =>
    readRemoteFile(folder.fileList[tableNumber].data, {
      complete: (results) => {
        if (results.errors.length > 0) {
          throw reject(results.errors.join(", "));
        }
        const cleanResults = results.data.filter((row) => row.some((elem) => elem));
        const csvParsed = {};
        csvParsed.columns = parseColumns(cleanResults.shift().filter((column) => column));
        csvParsed.dataSource = cleanResults.map((row) => {
          return csvParsed.columns.reduce((object, column, index) => {
            return { ...object, [column.key]: row[index] };
          }, {});
        });
        resolve(csvParsed);
      },
    })
  );
};
