import { Button, Card, Form, List } from "antd";
import React from "react";
import { Input, Spinner } from "..";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import { CONTENT, DATE, FIELDS, TITLE } from "../../constants";
import "./StandaloneForm.css";
import { Input as InputAntd } from "antd";
import Linkify from "react-linkify";

const { TextArea } = InputAntd;

//To use this the structure of standaloneData must contain
//  Title
//  Content
export default function StandaloneScreen({
  formId,
  standaloneData,
  publicationFormText,
  lastPublicationsText,
  onFinish,
  onDelete,
}) {
  const [standaloneForm] = Form.useForm();

  const handleFinish = (standaloneData) => {
    onFinish(standaloneData);
    standaloneForm.resetFields();
  };

  return (
    <div className="default-screen">
      <Card title={publicationFormText} className="standalone-form">
        <Form
          id={formId}
          data-testid="standalone-form"
          validateMessages={{ required: "Por favor complete: ${label}" }}
          form={standaloneForm}
          onFinish={handleFinish}
        >
          <Input
            name={[TITLE]}
            label="Titulo"
            placeholder="Escribe..."
            rules={[
              {
                required: true,
              },
            ]}
          />
          <Form.Item
            name={[CONTENT]}
            label="Contenido"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea placeholder="Escribe..." />
          </Form.Item>
          <Button type="primary" style={{ float: "right" }} form={formId} key="submit" htmlType="submit">
            Publicar
          </Button>
        </Form>
      </Card>
      <HorizontalLine color="rgba(0, 0, 255)" />
      <Card className="default-list" title={lastPublicationsText}>
        <List>
          <Spinner spinning={standaloneData.length === 0}>
            {standaloneData.map((standaloneItem, index) => (
              <List.Item key={index}>
                <List.Item.Meta
                  title={
                    <div className="default-title">
                      <h3>{standaloneItem[FIELDS][TITLE]}</h3>
                      <h4>{standaloneItem[FIELDS][DATE]}</h4>
                      {onDelete && <Button onClick={() => onDelete(standaloneItem.id)}>Eliminar</Button>}
                    </div>
                  }
                  description={
                    <Linkify
                      componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="blank" href={decoratedHref} key={key}>
                          {decoratedText}
                        </a>
                      )}
                    >
                      {standaloneItem[FIELDS][CONTENT]}
                    </Linkify>
                  }
                />
              </List.Item>
            ))}
          </Spinner>
        </List>
      </Card>
    </div>
  );
}
