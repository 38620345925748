import { ATTRIBUTEIDS, FIELDS, FOLDER } from "./constants";

export const formatDataToForm = (data) => {
  let formattedDataToForm = [];
  Object.entries(data).forEach((item) => {
    formattedDataToForm = formattedDataToForm.concat({
      name: [item[0]],
      value: item[1],
    });
  });
  return formattedDataToForm;
};

export const formatFolderToAPI = (folderFields) => {
  return {
    [FOLDER]: {
      [FIELDS]: {
        ...folderFields[FOLDER],
      },
    },
    [ATTRIBUTEIDS]: folderFields[ATTRIBUTEIDS],
  };
};

export const parseColumns = (columns) => {
  return columns.map((column) => {
    return {
      title: column,
      dataIndex: column,
      key: column,
    };
  });
};

export const parseRows = (rows) => {
  rows.map((row, index) => {
    row.key = index;
    return row;
  });
};

const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};

export const formatDate = (date) => {
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join("/");
};

export const groupByFoldersByFolderType = (folders) => {
  const groupedFolders = groupBy(folders, "folderTypeId");
  return Object.entries(groupedFolders);
};

export const getUserFolderTypeList = (groupedFolders) => {
  return groupedFolders.map((folder) => {
    return {
      id: folder[0],
      name: folder[1][0].folderType,
    };
  });
};

const groupBy = (array, key) => {
  return array.reduce((groupedResult, currentValue) => {
    (groupedResult[currentValue[key]] = groupedResult[currentValue[key]] || []).push(currentValue);
    return groupedResult;
  }, {});
};

export const getErrorLoadingListMsg = (listType, error) => {
  return `La lista de ${listType} no ha podido ser cargada. Envia el siguiente mensaje a soporte: ${error}`;
};
