import { Button, Modal, Card } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import React, { useEffect, useState } from "react";
import { UserList, UserForm, Spinner, Input } from "../../components";
import { auth } from "../../services/firebase";
import { addUser, deleteUser, editUser, getAllUsersView } from "../../services/user";

import "./UsersScreen.css";
import { getErrorLoadingListMsg } from "../../utils";

export default function UsersScreen() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    let isUnmounted = false;
    loadUsersList(isUnmounted);
    return () => (isUnmounted = true);
  }, []);

  useEffect(() => {
    setFilteredUsers([...users]);
  }, [users]);

  const loadUsersList = (isUnmounted) => {
    setIsLoading(true);
    getAllUsersView()
      .then((resUsers) => (!isUnmounted ? setUsers(resUsers) : null))
      .catch(
        (error) =>
          !isUnmounted &&
          modal.error({
            title: "Error",
            content: getErrorLoadingListMsg("usuarios", error),
          })
      )
      .finally(() => !isUnmounted && setTimeout(() => setIsLoading(false), 500));
  };

  const openModal = () => {
    setIsAddUserModalVisible(true);
  };

  const handleAdd = (user) => {
    setIsAddUserModalVisible(false);
    //loadUsersList will take care of turning isLoading false
    setIsLoading(true);
    addUser(user)
      .then(() => {
        loadUsersList();
      })
      .catch((error) =>
        modal.error({
          title: "Error",
          content: "El usuario no ha podido ser creado. " + `Por favor contacte con un administrador: ${error}`,
        })
      );
  };

  //Could not be set on API, no method for sendPasswordResetEmail found on firebase admin API
  const handleResetPassword = (mail) => {
    auth
      .sendPasswordResetEmail(mail)
      .then(() =>
        modal.success({
          title: "Finalizado",
          content: "Se ha enviado un mail de reestablecimiento de contraseña al usuario",
        })
      )
      .catch((error) =>
        modal.error({
          title: "Error",
          content:
            "El envio de mail para establecer una contraseña " +
            "no pudo ser ejecutada correctamente. Intente nuevamente " +
            `desde el listado de usuarios. ${error.code} - ${error.message}`,
        })
      );
  };

  const handleEdit = (user) => {
    //loadUsersList will take care of turning isLoading false
    setIsLoading(true);
    editUser(user)
      .then(() => loadUsersList())
      .catch((error) => modal.error({ title: "Error", content: error }));
  };

  const handleDelete = (id) => {
    //loadUsersList will take care of turning isLoading false
    setIsLoading(true);
    deleteUser(id)
      .then(() => {
        loadUsersList();
      })
      .catch((error) => modal.error({ title: "Error", content: error }));
  };

  const filterUsers = (userName) => {
    setFilteredUsers(
      users.filter((user) => user.userInfo.name_businessName.toLowerCase().includes(userName.toLowerCase()))
    );
  };

  return (
    <div data-testid="users-screen" className="users">
      <Spinner spinning={isLoading}>
        <Card
          className="content"
          title="Lista de usuarios"
          extra={[
            <Button key="1" title="Añadir Usuario" onClick={openModal}>
              Añadir Usuario
            </Button>,
          ]}
        >
          <Input suffix={<SearchOutlined />} placeholder="Buscar Usuario" className="search" onChange={filterUsers} />
          {users.length === 0 ? null : (
            <UserList
              users={filteredUsers}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleResetPassword={handleResetPassword}
            />
          )}
        </Card>
      </Spinner>
      <Modal
        onCancel={() => setIsAddUserModalVisible(false)}
        destroyOnClose
        title="Añadir usuario"
        open={isAddUserModalVisible}
        footer={null}
      >
        <UserForm
          id="addUserForm"
          onFinish={handleAdd}
          submitForm={
            <Button type="primary" style={{ float: "right" }} form="addUserForm" key="submit" htmlType="submit">
              Añadir
            </Button>
          }
        />
      </Modal>
      {contextHolder}
    </div>
  );
}
