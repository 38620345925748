import React, { useEffect } from "react";
import { Form } from "antd";
import { Input } from "..";
import { FOLDERTYPE, NAME } from "../../constants";

export default function FolderTypeForm({
  id,
  onFinish,
  item: folderType = null,
  form,
}) {
  useEffect(() => {
    if (folderType) {
      const formattedFolderType = formatFolderTypeToForm(folderType);
      form.setFieldsValue(formattedFolderType);
    }
  });

  const formatFolderTypeToForm = (folderType) => {
    return {
      [FOLDERTYPE]: folderType[NAME],
    };
  };

  const handleFinish = (folderTypeFields) => {
    onFinish(formatFolderTypeToAPI(folderTypeFields));
    if (!form) {
      return;
    }
    form.resetFields();
  };

  const formatFolderTypeToAPI = (folderTypeFields) => {
    return {
      fields: {
        [FOLDERTYPE]: folderTypeFields[FOLDERTYPE],
      },
    };
  };

  return (
    <Form id={id} form={form} onFinish={handleFinish}>
      <Input
        name={FOLDERTYPE}
        label="Nombre de tipo de carpeta"
        placeholder="Ingrese..."
        rules={[
          {
            required: true,
          },
        ]}
      />
    </Form>
  );
}
