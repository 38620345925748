import { Checkbox, Form } from "antd";
import { DropdownSelect, Input } from "..";
import { formatFolderToAPI } from "../../utils";
import React, { useEffect, useState } from "react";
import { getAllAttributesTypesView } from "../../services/attributeType";
import {
  ATTRIBUTEIDS,
  MONTHLY,
  DAILY,
  YEARLY,
  WEEKLY,
  FOLDERNAME,
  ISLOADABLE,
  HASPERIOD,
  PERIODTYPE,
  FOLDER,
  DATATYPE,
  PDFANDIMAGE,
  TABLE,
  TEXT,
  REQUEST,
} from "../../constants";
import { existUserFolderWithFolderId, getAttributeIdsFromExistingFolder } from "../../services/folder";

export default function FolderForm({ id, onFinish, subItem: folder, form }) {
  const [attributes, setAttributes] = useState([]);
  const [hasPeriod, setHasPeriod] = useState(false);
  const [isAllowedToChangeIsLoadable, setIsAllowedToChangeIsLoadable] = useState(false);
  const [isFolderEditable, setIsFolderEditable] = useState(true); //Folder will alway be editable on name

  useEffect(() => {
    let isUnmounted = false;
    getAllAttributesTypesView().then(
      (resAttributesType) => !isUnmounted && setAttributes(formatAttributeTypeToAttributes(resAttributesType))
    );
    if (folder) {
      prepareDataOnForm(folder); //YET TO GET ATTRIBUTES FROM FOLDER
    }
    return () => {
      isUnmounted = true;
    };
  }, []);

  const checkIsFolderEditable = async (folderId) => {
    return !(await existUserFolderWithFolderId(folderId));
  };

  const prepareDataOnForm = async (folder) => {
    checkIsFolderEditable(folder.id).then((result) => {
      setIsFolderEditable(result);
    });
    const attributeIds = await getAttributeIdsFromExistingFolder(folder.id);
    form.setFieldsValue(formatFolderToForm(folder, attributeIds));
    setHasPeriod(folder.hasPeriod);
    onDataTypeChange(folder.dataType);
  };

  const formatFolderToForm = (folder, attributeIds) => {
    return {
      [FOLDER]: {
        [FOLDERNAME]: folder.name,
        [DATATYPE]: folder.dataType,
        [HASPERIOD]: folder.hasPeriod,
        [ISLOADABLE]: folder.isLoadable,
        [PERIODTYPE]: folder.periodType,
      },
      [ATTRIBUTEIDS]: attributeIds,
    };
  };

  const formatAttributeTypeToAttributes = (attributeTypes) => {
    return attributeTypes.reduce((formattedAttributes, attributeType) => {
      attributeType.attributes.forEach((attribute) => {
        formattedAttributes.push({
          name: `${attributeType.name} - ${attribute.name}`,
          id: attribute.id,
        });
      });
      return formattedAttributes;
    }, []);
  };

  const handleFinish = (folderFields) => {
    folderFields.folder.isLoadable = checkIsLoadable(folderFields.folder);
    const formattedFolder = formatFolderToAPI(folderFields);
    if (folder) {
      formattedFolder.folder.id = folder.id;
    }
    onFinish(formattedFolder);
    if (!form) {
      return;
    }
    form.resetFields();
  };

  const checkIsLoadable = (folder) => {
    return folder.dataType === REQUEST ? true : folder.dataType !== PDFANDIMAGE ? false : folder.isLoadable;
  };

  const onChangeHasPeriod = (e) => {
    setHasPeriod(e.target.checked);
  };

  const onDataTypeChange = (value) => {
    setIsAllowedToChangeIsLoadable(value === PDFANDIMAGE);
  };

  return (
    <Form
      id={id}
      form={form}
      onFinish={handleFinish}
      validateMessages={{ required: "Por favor complete: ${label}" }}
      preserve={false}
    >
      <Input
        name={[FOLDER, FOLDERNAME]}
        label="Nombre de carpeta"
        rules={[
          {
            required: true,
          },
        ]}
      />
      <DropdownSelect
        name={[FOLDER, DATATYPE]}
        label="Formato de archivo"
        disabled={!isFolderEditable}
        onChange={onDataTypeChange}
        rules={[
          {
            required: true,
          },
        ]}
        data={[
          {
            name: "Tabla",
            id: TABLE,
          },
          {
            name: "PDF o Imagen",
            id: PDFANDIMAGE,
          },
          {
            name: "Campo de Texto",
            id: TEXT,
          },
          {
            name: "Solicitud",
            id: REQUEST,
          },
        ]}
      />
      <Form.Item
        name={[FOLDER, ISLOADABLE]}
        hidden={!isAllowedToChangeIsLoadable}
        valuePropName="checked"
        wrapperCol={{ offset: 8, span: 16 }}
        initialValue={false}
      >
        <Checkbox disabled={!isFolderEditable} defaultChecked={false}>
          Es cargable por usuario
        </Checkbox>
      </Form.Item>
      <Form.Item
        name={[FOLDER, HASPERIOD]}
        valuePropName="checked"
        wrapperCol={{ offset: 8, span: 16 }}
        initialValue={false}
      >
        <Checkbox disabled={!isFolderEditable} defaultChecked={false} onChange={onChangeHasPeriod}>
          Tiene periodo
        </Checkbox>
      </Form.Item>
      {hasPeriod && (
        <DropdownSelect
          name={[FOLDER, PERIODTYPE]}
          label="Tipo de periodo"
          disabled={!isFolderEditable}
          rules={[
            {
              required: hasPeriod,
            },
          ]}
          data={[
            {
              name: "Diario",
              id: DAILY,
            },
            {
              name: "Semanal",
              id: WEEKLY,
            },
            {
              name: "Mensual",
              id: MONTHLY,
            },
            {
              name: "Anual",
              id: YEARLY,
            },
          ]}
          placeholder="Elija..."
        />
      )}
      <DropdownSelect
        name={ATTRIBUTEIDS}
        label="Atributos"
        disabled={!isFolderEditable}
        rules={[
          {
            required: true,
          },
        ]}
        mode="multiple"
        data={attributes}
        placeholder="Elija..."
      />
    </Form>
  );
}
