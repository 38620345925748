import React from "react";
import { Spin } from "antd";

import "./Spinner.css";

export default function Spinner({ classname, spinning, children }) {
  return (
    <Spin className={`${classname}`} tip="Cargando" spinning={spinning}>
      {children}
    </Spin>
  );
}
