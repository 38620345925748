import { del, getAll } from "./commonRequests";

const REGISTER_REQUEST_PATH = "/register_request";

export const deleteRegisterRequest = (id) => {
  return del(REGISTER_REQUEST_PATH, id);
};

export const getAllRegisterRequests = () => {
  return getAll(REGISTER_REQUEST_PATH);
};
