import React, { useEffect } from "react";
import { Form } from "antd";
import { Input } from "..";
import { ATTRIBUTE, FIELDS } from "../../constants";

export default function AttributeForm({
  id,
  onFinish,
  subItem: attribute = null,
  form,
}) {
  const handleFinish = (attributeFields) => {
    const formattedAttribute = formatAttributeToAPI(attributeFields);
    if (attribute) {
      formattedAttribute.id = attribute.id;
    }
    onFinish(formattedAttribute);
    if (!form) {
      return;
    }
    form.resetFields();
  };

  const formatAttributeToAPI = (attributeFields) => {
    return {
      [FIELDS]: {
        ...attributeFields,
      },
    };
  };

  useEffect(() => {
    if (attribute) {
      if (!form) {
        return;
      }
      form.setFieldsValue(formatAttributeToForm(attribute));
    }
  }, []);

  const formatAttributeToForm = (attribute) => {
    return {
      [ATTRIBUTE]: attribute.name,
    };
  };

  return (
    <Form
      id={id}
      form={form}
      onFinish={handleFinish}
      validateMessages={{ required: "Por favor complete: ${label}" }}
    >
      <Input
        name={ATTRIBUTE}
        label="Nombre de atributo"
        rules={[
          {
            required: true,
          },
        ]}
      />
    </Form>
  );
}
