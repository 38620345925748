import { Button, Card, List, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { getUser } from "../../services/user";
import { getRequests, deleteUserFolderData } from "../../services/userFolder";
import "./RequestsScreen.css";
import { getErrorLoadingListMsg } from "../../utils";
import { Spinner } from "../../components";

export default function RequestsScreen() {
  const [requestList, setRequestList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    let isUnmounted = false;
    loadRequest(isUnmounted);
    return () => {
      isUnmounted = true;
    };
  }, []);

  const loadRequest = (isUnmounted) => {
    setIsLoading(true);
    getRequests()
      .then(async (requests) => {
        let requestWithUser = [];
        for (let request of requests) {
          const user = await getUser(request.userId);
          requestWithUser.push({ ...request, userName: user.fields.name_businessName, email: user.fields.mail });
        }
        !isUnmounted && setRequestList(requestWithUser);
      })
      .catch(
        (error) =>
          !isUnmounted && modal.error({ title: "Error", content: getErrorLoadingListMsg("solicitudes", error) })
      )
      .finally(() => !isUnmounted && setIsLoading(false));
  };

  const finishRequest = (requestId) => {
    deleteUserFolderData(requestId)
      .then(() => modal.success({ title: "Finalizado", content: "La solicitud ha sido marcada como finalizada" }))
      .catch((error) => modal.error({ title: "Error", content: "algo ha fallado: " + error }))
      .finally(() => loadRequest());
  };

  return (
    <div className="default-screen">
      <Card title={"Solicitudes pendientes"} className="default-list">
        <List>
          <Spinner spinning={isLoading}>
            {requestList.map((request, index) => (
              <List.Item key={index}>
                <List.Item.Meta
                  title={
                    <div className="default-title">
                      <h3>Carpeta: {request.folderName}</h3>
                      <h4>Usuario: {request.userName}</h4>
                      <h4>Email: {request.email}</h4>
                      <Button onClick={() => finishRequest(request.id)}>Finalizar solicitud</Button>
                    </div>
                  }
                  description={
                    <div>
                      <p>{request.request}</p>
                    </div>
                  }
                />
              </List.Item>
            ))}
          </Spinner>
        </List>
      </Card>
      {contextHolder}
    </div>
  );
}
