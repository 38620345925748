import {
  DeleteOutlined,
  EditOutlined,
  KeyOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Descriptions, DatePicker, Modal, Image, Form, Input, List, Table, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { getUserFolderData, sendFolderDataOfUser, sendFolderDataOfUserByBody } from "../../services/user";
import { Document, pdfjs, Page } from "react-pdf";

import { getTableData } from "../../services/utilsCalls";

import "./User.css";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import { DATA, EXT, FILENAME, INIT_PAGE, PDFANDIMAGE, REQUEST, TABLE, TEXT } from "../../constants";
import Linkify from "react-linkify";
import { getUserFolderTypeList, groupByFoldersByFolderType } from "../../utils";
import DropdownSelect from "../DropdownSelect/DropdownSelect";
import Dragger from "antd/lib/upload/Dragger";
import { deleteUserFolderDataFile } from "../../services/userFolder";

const { TextArea } = Input;
const NO_PERIOD = "Sin periodo";

export default function User({ user, onEdit, onDelete, onResetPassword }) {
  const [periodModalVisible, setPeriodModalVisible] = useState(false);
  const [dataVisualUploadModalVisible, setDataVisualUploadModalVisible] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [folders, setFolders] = useState([]);
  const [folderTypeList, setFolderTypeList] = useState([]);
  const [selectedFolderType, setSelectedFolderType] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [modal, contextHolder] = Modal.useModal();

  const [periodForm] = Form.useForm();
  const [uploadForm] = Form.useForm();

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [fileNumber, setFileNumber] = useState(0);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(INIT_PAGE);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const openModalDatePicker = (folder) => {
    setSelectedFolder(folder);
    if (folder.periodType) {
      setPeriodModalVisible(true);
    } else {
      getFolderData(user, folder);
    }
  };

  const getPeriodPicker = (periodType) => {
    const periodTypesToPeriodPicker = {
      YEARLY: "year",
      MONTHLY: "month",
      WEEKLY: "week",
      DAILY: "date",
    };
    return periodTypesToPeriodPicker[periodType];
  };

  useEffect(() => {
    if (user.folders) {
      setFolders(groupByFoldersByFolderType(user.folders));
    }
  }, [user.folders]);

  useEffect(() => {
    if (folders.length > 0) {
      setFolderTypeList(getUserFolderTypeList(folders));
    }
  }, [folders]);

  useEffect(() => {
    setFileNumber(0);
  }, [selectedFolder]);

  useEffect(() => {
    let isUnmounted = false;
    if (
      selectedFolder &&
      selectedFolder.fileList &&
      selectedFolder.fileList.length !== 0 &&
      selectedFolder.dataType === "TABLE"
    ) {
      getTableData(selectedFolder, fileNumber).then((csvParsed) => {
        if (isUnmounted) {
          return;
        }
        setTableData(csvParsed);
      });
    }
    return () => (isUnmounted = true);
  }, [selectedFolder]);

  const getFolderData = (user, folder, period) => {
    const periodDate = period ? period.toISOString() : null;
    getUserFolderData(user.userInfo.id, folder.id, periodDate)
      .then((folderData) => {
        setSelectedFolder({
          ...folder,
          dataId: folderData.id,
          fileList: folderData.files
            ? folderData.files.map((file) => {
                return { fileName: file.fileName, data: file.data, ext: file.fileExtension };
              })
            : [],
          period: periodDate,
        });
        setDataVisualUploadModalVisible(true);
      })
      .catch(() => {
        setDataVisualUploadModalVisible(false);
        modal.error({
          title: "Error",
          content: "No ha sido posible presentar la información para el periodo seleccionado",
        });
      });
  };

  const finishPeriodForm = ({ period }) => {
    getFolderData(user, selectedFolder, period);
    setPeriodModalVisible(false);
    periodForm.resetFields();
  };

  const checkFile = (file) => {
    if (!selectedFolder) {
      return null;
    }
    if (
      file.type === "application/pdf" ||
      file.type === "image/png" ||
      (file.type === "image/jpeg" && selectedFolder.dataType === "PDFANDIMAGE")
    ) {
      return false;
    }
    if ((file.type === "text/csv" || file.type === "application/vnd.ms-excel") && selectedFolder.dataType === "TABLE") {
      return false;
    }
    modal.error({
      title: "Error",
      content: `El tipo de archivo cargado (${file.type}) no coincide con el tipo de carpeta (${getSpecificFolderType(
        selectedFolder.dataType
      )})`,
    });
    uploadForm.resetFields();
    return true;
  };

  const getSpecificFolderType = (genericFolderType) => {
    switch (genericFolderType) {
      case PDFANDIMAGE:
        return "application/pdf, image/png o image/jpeg";
      case TABLE:
        return "text/csv o application/vnd.ms-excel";
      default:
        return "";
    }
  };

  const uploadData = (data) => {
    setIsUploading(true);
    if (selectedFolder.dataType === "TEXT") {
      const parsedData = data.dataList.map((dataItem, index) => {
        return {
          [FILENAME]: index.toString(),
          [DATA]: dataItem,
          [EXT]: "txt",
        };
      });
      sendFolderDataOfUserByBody(user.userInfo.id, selectedFolder.id, selectedFolder.period, parsedData)
        .then(() => {
          getFolderData(user, selectedFolder, new Date(selectedFolder.period));
        })
        .catch(() => {
          modal.error({
            title: "Error",
            content: "Error al subir el archivo, contacte con soporte",
          });
        })
        .finally(() => {
          setIsUploading(false);
          uploadForm.resetFields();
        });
      return;
    }
    const fileList = data.uploadData.fileList;
    if (fileList.length === 0) {
      modal.error({
        title: "Error",
        content: "No se ha seleccionado ningún archivo",
      });
      return;
    }
    let formData = new FormData();
    for (const file of fileList) {
      formData.append("file", file.originFileObj);
    }
    sendFolderDataOfUser(user.userInfo.id, selectedFolder.id, selectedFolder.period, formData)
      .then(() => {
        getFolderData(user, selectedFolder, new Date(selectedFolder.period));
      })
      .catch(() => {
        modal.error({
          title: "Error",
          content: "Error al subir el archivo, contacte con soporte",
        });
      })
      .finally(() => {
        setIsUploading(false);
        uploadForm.resetFields();
      });
  };

  const deleteFile = (fileName) => {
    setIsDeleting(true);
    deleteUserFolderDataFile({ id: selectedFolder.dataId, fileName })
      .then(() => {
        modal.success({
          title: "Eliminación exitosa",
          content: "El archivo ha sido eliminado",
        });
        getFolderData(user, selectedFolder, new Date(selectedFolder.period));
      })
      .catch((error) =>
        modal.error({
          title: "Error",
          content: `Error al eliminar el archivo, contacte con soporte. ${error}`,
        })
      )
      .finally(() => setIsDeleting(false));
  };

  const showModalDataTitle = (folder) => {
    if (!folder.period) {
      return NO_PERIOD;
    }
    const periodDate = new Date(folder.period);
    const dd = String(periodDate.getDate()).padStart(2, "0");
    const mm = String(periodDate.getMonth() + 1).padStart(2, "0");
    const yyyy = periodDate.getFullYear();
    const periodTypeToTitleString = {
      YEARLY: yyyy,
      MONTHLY: `${mm}/${yyyy}`,
      WEEKLY: `Semana ${Math.ceil(dd / 7)} - ${mm}/${yyyy}`,
      DAILY: `${dd}/${mm}/${yyyy}`,
    };
    const periodFormatted = periodTypeToTitleString[folder.periodType];
    if (!periodFormatted) {
      return NO_PERIOD;
    }
    return folder.name + " - Fecha elegida: " + periodFormatted;
  };

  const changeFileNumber = (offset) => {
    setFileNumber((prevFileNumber) => prevFileNumber + offset);
    setPageNumber(INIT_PAGE);
  };

  const showData = (folder, fileNumber) => {
    if (folder.dataType === PDFANDIMAGE) {
      if (folder.fileList[fileNumber].ext.toLowerCase() === ".pdf") {
        return (
          <div className="pdf-container">
            <Document file={folder.fileList[fileNumber].data} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
            <div className="pdf-controls">
              <Button className="pdf-controls-button" type="primary" onClick={previousPage} disabled={pageNumber <= 1}>
                Página anterior
              </Button>
              <Button
                className="pdf-controls-button"
                type="primary"
                onClick={nextPage}
                disabled={pageNumber >= numPages}
              >
                Página siguiente
              </Button>
            </div>
          </div>
        );
      }
      return <Image src={folder.fileList[fileNumber].data} alt="Imagen" className="image-container" />;
    }
    if (folder.dataType === TABLE && tableData) {
      return (
        <div className="table-container">
          <Table dataSource={tableData.dataSource} columns={tableData.columns} />
        </div>
      );
    }
    if (folder.dataType === TEXT && folder.dataType === REQUEST) {
      return (
        <div className="text-container">
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {folder.fileList[fileNumber].data}
          </Linkify>
        </div>
      );
    }
  };

  const showUploadTextForm = () => (
    <Form name="dynamic_form_item" onFinish={uploadData}>
      <Form.List
        name="dataList"
        rules={[
          {
            validator: async (_, data) => {
              if (!data || data.length < 1) {
                return Promise.reject(new Error("Al menos un campo de informacion debe ser ingresado"));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item label={index === 0 ? "Información" : ""} required={false} key={field.key}>
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Por favor, incluye información en el campo",
                    },
                  ]}
                  noStyle
                >
                  <TextArea
                    placeholder="Información"
                    style={{
                      width: "60%",
                    }}
                  />
                </Form.Item>
                {fields.length > 1 ? <MinusCircleOutlined onClick={() => remove(field.name)} /> : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{
                  width: "60%",
                }}
                icon={<PlusOutlined />}
              >
                Añadir campo de información
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Subir información
        </Button>
      </Form.Item>
    </Form>
  );

  const filterFolders = (folderType) => {
    setSelectedFolderType(folders.filter((folder) => folder[0] === folderType)[0]);
  };

  const checkSelectAnotherPeriodModal = () => {
    modal.confirm({
      title: "Quieres selecionar otra fecha?",
      okText: "Si",
      cancelText: "No",
      onOk() {
        setPeriodModalVisible(true);
      },
      onCancel() {},
    });
  };

  return (
    <div>
      {user && (
        <Descriptions
          title="Información de usuario"
          bordered
          extra={[
            <Button key={0} onClick={() => onEdit(user)}>
              <EditOutlined />
            </Button>,
            <Popconfirm
              title="Deseas eliminar?"
              key={1}
              onConfirm={() => onDelete(user.userInfo.id)}
              cancelText="No"
              okText="Si"
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>,
            <Button key={2} onClick={() => onResetPassword(user.userInfo.mail)}>
              <KeyOutlined />
            </Button>,
          ]}
        >
          <Descriptions.Item label="Nombre">{user.userInfo.name_businessName}</Descriptions.Item>
          <Descriptions.Item label="Identificación">{user.userInfo.identification}</Descriptions.Item>
          <Descriptions.Item label="Mail">{user.userInfo.mail}</Descriptions.Item>
          {folders && (
            <Descriptions.Item label="Carpetas">
              <DropdownSelect
                name="folderTypeSelect"
                label="Seleccione la carpeta"
                data={folderTypeList}
                onChange={(folderType) => filterFolders(folderType)}
              />
              {selectedFolderType && (
                <List
                  key={selectedFolderType[0]}
                  header={
                    <div>
                      <b>{selectedFolderType[1][0].folderType}</b>
                    </div>
                  }
                >
                  {selectedFolderType[1].map((folder, index) => (
                    <List.Item key={index}>
                      <Button key={folder.id} onClick={() => openModalDatePicker(folder, user)}>
                        {folder.name}
                      </Button>
                    </List.Item>
                  ))}
                </List>
              )}
            </Descriptions.Item>
          )}
        </Descriptions>
      )}
      <Modal
        destroyOnClose
        onCancel={() => setPeriodModalVisible(false)}
        title="Seleccionar Periodo"
        open={periodModalVisible}
        footer={[
          <Button form="period-form" key="submit" htmlType="submit">
            Seleccionar
          </Button>,
        ]}
      >
        {selectedFolder && (
          <Form
            id="period-form"
            form={periodForm}
            onFinish={finishPeriodForm}
            validateMessages={{ required: "Por favor complete: ${label}" }}
          >
            <Form.Item
              name="period"
              label="Seleccione la fecha de la carpeta"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker picker={getPeriodPicker(selectedFolder.periodType)} />
            </Form.Item>
          </Form>
        )}
      </Modal>
      {selectedFolder && (
        <Modal
          destroyOnClose
          onCancel={() => {
            setDataVisualUploadModalVisible(false);
            selectedFolder.hasPeriod && checkSelectAnotherPeriodModal();
            uploadForm.resetFields();
          }}
          title={showModalDataTitle(selectedFolder)}
          open={dataVisualUploadModalVisible}
          width={1000}
          footer={
            !selectedFolder.isLoadable &&
            selectedFolder.dataType !== "TEXT" && [
              <Button loading={isUploading} form="upload-form" key="submit" htmlType="submit">
                Cargar archivo
              </Button>,
            ]
          }
        >
          {selectedFolder.fileList &&
            selectedFolder.fileList.length > 0 &&
            selectedFolder.fileList[fileNumber] &&
            selectedFolder.fileList[fileNumber].data && (
              <div className="data-container">
                <div
                  style={{
                    display: "flex",
                    width: "-webkit-fill-available",
                    justifyContent: selectedFolder.fileList.length === 1 ? "center" : "space-around",
                  }}
                >
                  {selectedFolder.dataType !== TEXT && selectedFolder.dataType !== REQUEST && (
                    <div>
                      <Button
                        loading={isDeleting}
                        onClick={() => deleteFile(selectedFolder.fileList[fileNumber].fileName)}
                        style={{ marginBottom: "10px" }}
                      >
                        Borrar archivo
                      </Button>
                      <Button
                        href={selectedFolder.fileList[fileNumber].data}
                        download
                        target="_blank"
                        style={{ marginBottom: "10px" }}
                      >
                        Descargar archivo
                      </Button>
                    </div>
                  )}
                  <div>
                    <Button
                      hidden={selectedFolder.fileList.length === 1}
                      disabled={fileNumber <= 0}
                      onClick={() => changeFileNumber(-1)}
                    >
                      Archivo anterior
                    </Button>
                    <Button
                      hidden={selectedFolder.fileList.length === 1}
                      disabled={fileNumber >= selectedFolder.fileList.length - 1}
                      onClick={() => changeFileNumber(1)}
                    >
                      Siguiente archivo
                    </Button>
                  </div>
                </div>
                {showData(selectedFolder, fileNumber)}
              </div>
            )}
          {selectedFolder.fileList && selectedFolder.fileList[fileNumber] && !selectedFolder.isLoadable && (
            <HorizontalLine color="rgba(0, 0, 0, 0.35)" />
          )}
          {!selectedFolder.isLoadable &&
            (selectedFolder.dataType !== TEXT ? (
              <Form
                id="upload-form"
                form={uploadForm}
                onFinish={uploadData}
                validateMessages={{
                  required: "Por favor seleccione un archivo",
                }}
              >
                <Form.Item
                  name="uploadData"
                  valuePropName="file"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Dragger customRequest={() => {}} multiple={true} beforeUpload={checkFile}>
                    <p className="ant-upload-drag-icon">
                      <UploadOutlined />
                    </p>
                    <p className="ant-upload-text">Arraste el/los archivo a cargar</p>
                    <p className="ant-upload-hint">
                      Formatos soportados en esta carpeta:{" "}
                      {selectedFolder.dataType === PDFANDIMAGE ? ".pdf, .jpeg, .png" : ".csv"}
                    </p>
                  </Dragger>
                </Form.Item>
              </Form>
            ) : (
              showUploadTextForm()
            ))}
        </Modal>
      )}
      {contextHolder}
    </div>
  );
}
