import { add, del, getAll } from "./commonRequests";

const USEFULLINKS_PATH = "/useful_links";

export const addUsefulLink = (usefulLink) => {
  return add(USEFULLINKS_PATH, usefulLink);
};

export const deleteUsefulLink = (id) => {
  return del(USEFULLINKS_PATH, id);
};

export const getAllUsefulLink = () => {
  return getAll(USEFULLINKS_PATH);
};
