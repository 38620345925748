import React from "react";
import { Input as InputData } from "antd";
import FormItem from "antd/lib/form/FormItem";

export default function Input({ label, placeholder, type, name, rules, onChange = null, min, max, prefix, suffix }) {
  return (
    <FormItem label={label} name={name} rules={rules}>
      <InputData
        prefix={prefix}
        suffix={suffix}
        data-testid="input"
        min={min}
        max={max}
        type={type}
        placeholder={placeholder}
        onChange={onChange ? (e) => onChange(e.target.value) : null}
      />
    </FormItem>
  );
}
