export const API_URL = "https://api.taxapp.com.ar/api"; //"http://190.51.2.169:3000/api";

export const FIELDS = "fields";
export const NAME = "name";
export const ID = "id";
export const USER = "user";
export const USERINFO = "userInfo";
export const NAME_BUSINESSNAME = "name_businessName";
export const IDENTIFICATION = "identification";
export const MAIL = "mail";
export const TAXPAYERTYPEID = "taxpayerTypeId";
export const TAXPAYERTYPEIDS = "taxpayerTypeIds";

export const ATTRIBUTEIDS = "attributeIds";

export const ATTRIBUTES = "attributes";
export const ATTRIBUTETYPE = "attributeType";
export const ATTRIBUTE = "attributeValue";

export const FOLDERTYPE = "folderType";
export const FOLDERTYPEID = "folderTypeId";
export const FOLDER = "folder";

export const FOLDERNAME = "folderName";
export const ISLOADABLE = "isLoadable";
export const HASPERIOD = "hasPeriod";
export const PERIODTYPE = "periodType";
export const DATATYPE = "dataType";

export const DAILY = "DAILY";
export const WEEKLY = "WEEKLY";
export const MONTHLY = "MONTHLY";
export const YEARLY = "YEARLY";

export const TABLE = "TABLE";
export const PDFANDIMAGE = "PDFANDIMAGE";
export const TEXT = "TEXT";
export const REQUEST = "REQUEST";
export const INIT_PAGE = 1;

export const NEWS = "news";
export const TITLE = "title";
export const CONTENT = "content";
export const DATE = "date";

export const USEFULLINK = "usefulLink";

export const FILENAME = "fileName";
export const DATA = "data";
export const EXT = "ext";

// This is not safely implemented, but as it is just for internal case on internal users of the same account, it is okay
// Future admin users and superuser should have implemented their feature security with the database
export const UNLOCK_FOLDERS_ATTRIBUTES_PWD = "Bk*181183";
