import React, { useState } from "react";
import { User, UserForm } from "../";
import { Collapse, Modal, Button } from "antd";

import "./UserList.css";

const { Panel } = Collapse;

export default function UserList({ users = [], handleEdit, handleDelete, handleResetPassword }) {
  const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const onEdit = (user) => {
    setSelectedUser(user);
    setIsEditUserModalVisible(true);
  };

  const onFinishEdit = (userData) => {
    setSelectedUser(null);
    handleEdit(userData);
    setIsEditUserModalVisible(false);
  };

  return (
    <div data-testid="user-list" className="user-list">
      <Collapse accordion>
        {users.map((user) => (
          <Panel header={user.userInfo.name_businessName} key={user.userInfo.id}>
            <User user={user} onDelete={handleDelete} onEdit={onEdit} onResetPassword={handleResetPassword} />
          </Panel>
        ))}
      </Collapse>
      {/* Edit User */}
      <Modal
        onCancel={() => setIsEditUserModalVisible(false)}
        destroyOnClose
        title="Editar Usuario"
        open={isEditUserModalVisible}
        footer={null}
      >
        <UserForm
          id="editUserForm"
          onFinish={onFinishEdit}
          user={selectedUser}
          submitForm={
            <Button type="primary" style={{ float: "right" }} form="editUserForm" key="submit" htmlType="submit">
              Guardar Cambios
            </Button>
          }
        />
      </Modal>
    </div>
  );
}
