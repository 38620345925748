import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";

import "./AttributesScreen.css";
import {
  addAttributeType,
  deleteAttributeType,
  editAttributeType,
  getAllAttributesTypesView,
} from "../../services/attributeType";
import { AttributeForm, List as AttributeList, AttributeTypeForm, Spinner } from "../../components";
import { addAttribute, deleteAttribute, editAttribute } from "../../services/attribute";
import { ATTRIBUTETYPE, ID, TAXPAYERTYPEIDS } from "../../constants";
import { getErrorLoadingListMsg } from "../../utils";

export default function AttributesScreen() {
  const [attributeTypes, setAttributeTypes] = useState([]);
  const [isAttributeTypeModalVisible, setIsAttributeTypeModalVisible] = useState(false);
  const [isAttributeModalVisible, setIsAttributeModalVisible] = useState(false);
  const [attributeTypeIdSelected, setAttributeTypeIdSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    let isUnmounted = false;
    loadAttributes(isUnmounted);
    return () => {
      isUnmounted = true;
    };
  }, []);

  const loadAttributes = (isUnmounted) => {
    setIsLoading(true);
    getAllAttributesTypesView()
      .then((resAttributeTypes) => {
        const attributeTypeFormatted = resAttributeTypes.map((data) => {
          return {
            name: data.name,
            id: data.id,
            subItems: data.attributes,
          };
        });
        !isUnmounted && setAttributeTypes(attributeTypeFormatted);
      })
      .catch(
        (error) =>
          !isUnmounted &&
          modal.error({
            title: "Error",
            content: getErrorLoadingListMsg("atributos", error),
          })
      )
      .finally(() => !isUnmounted && setIsLoading(false));
  };

  //AttributeTypes
  const handleAddAttributeType = () => {
    setIsAttributeTypeModalVisible(true);
  };

  const handleFinishAttributeType = (attributeType) => {
    addAttributeType(attributeType)
      .then(() => {
        setIsAttributeTypeModalVisible(false);
        loadAttributes();
      })
      .catch((err) => console.error(err));
  };

  const handleDeleteAttributeType = (id) => {
    deleteAttributeType(id)
      .then(() => loadAttributes())
      .catch((err) => console.error(err));
  };

  const handleEditAttributeType = (attributeTypeData, attributeTypeId) => {
    const attributeTypeToSend = {
      [ATTRIBUTETYPE]: {
        ...attributeTypeData[ATTRIBUTETYPE],
        [ID]: attributeTypeId,
      },
      [TAXPAYERTYPEIDS]: attributeTypeData[TAXPAYERTYPEIDS],
    };
    editAttributeType(attributeTypeToSend).then(() => loadAttributes());
  };

  //Attributes
  const handleAddAttribute = (id) => {
    setAttributeTypeIdSelected(id);
    setIsAttributeModalVisible(true);
  };

  const handleFinishAddAttribute = (attribute) => {
    attribute.fields.attributeTypeId = attributeTypeIdSelected;
    addAttribute(attribute).then(() => {
      setIsAttributeModalVisible(false);
      loadAttributes();
    });
  };

  const handleEditAttribute = (attribute, attributeTypeId) => {
    attribute.fields.attributeTypeId = attributeTypeId;
    editAttribute(attribute).then(() => {
      loadAttributes();
    });
  };

  const handleDeleteAttribute = (id) => {
    deleteAttribute(id)
      .then(() => loadAttributes())
      .catch((err) => console.error(err));
  };

  return (
    <div className="attributes">
      <div className="content-title">
        <h2>Atributos</h2>
        <Button onClick={handleAddAttributeType}>Nuevo Tipo de Atributo</Button>
      </div>
      <Spinner spinning={isLoading}>
        <AttributeList
          data={attributeTypes}
          handleItemDelete={handleDeleteAttributeType}
          handleItemEdit={handleEditAttributeType}
          handleSubItemAdd={handleAddAttribute}
          handleSubItemEdit={handleEditAttribute}
          handleSubItemDelete={handleDeleteAttribute}
          ItemForm={AttributeTypeForm}
          SubItemForm={AttributeForm}
          itemModalTitle={"Editar tipo de atributo"}
          subItemModalTitle={"Editar atributo"}
          isEditOnSubItemEnabled={() => true}
          itemFormKey={ATTRIBUTETYPE}
          subItemFormKey={ATTRIBUTETYPE}
        />
      </Spinner>
      <Modal
        title="Añadir Tipo de Atributo"
        onCancel={() => setIsAttributeTypeModalVisible(false)}
        open={isAttributeTypeModalVisible}
        footer={[
          <Button form="addAttributeTypeForm" key="submit" htmlType="submit">
            Añadir
          </Button>,
        ]}
      >
        <AttributeTypeForm id="addAttributeTypeForm" onFinish={handleFinishAttributeType} />
      </Modal>
      <Modal
        title="Añadir Atributo"
        onCancel={() => setIsAttributeModalVisible(false)}
        open={isAttributeModalVisible}
        footer={[
          <Button form="addAttributeForm" key="submit" htmlType="submit">
            Añadir
          </Button>,
        ]}
      >
        <AttributeForm id="addAttributeForm" onFinish={handleFinishAddAttribute} />
      </Modal>
      {contextHolder}
    </div>
  );
}
