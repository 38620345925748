import { add, del, edit, getAll, getRelation } from "./commonRequests";

const ATTRIBUTETYPES_PATH = "/attribute_type";

export const getAllAttributesTypesView = () => {
  return getAll(`${ATTRIBUTETYPES_PATH}_view`);
};

export const addAttributeType = (attributeType) => {
  return add(ATTRIBUTETYPES_PATH, attributeType);
};

export const editAttributeType = (attributeType) => {
  return edit(ATTRIBUTETYPES_PATH, attributeType);
};

export const deleteAttributeType = (id) => {
  return del(ATTRIBUTETYPES_PATH, id);
};

export const getTaxpayerTypesSelected = (attributeTypeId) => {
  return getRelation(ATTRIBUTETYPES_PATH, attributeTypeId, "taxpayer_type");
};
