import React from "react";
import { Button, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import "./ListItem.css";

export default function ListItem({ subItem, handleEdit, handleDelete, isEditEnabled, itemId }) {
  return (
    <div className="list-item">
      <p>{subItem.name}</p>
      <div className="actions">
        {isEditEnabled && (
          <Button onClick={() => handleEdit(subItem, itemId)}>
            <EditOutlined />
          </Button>
        )}
        <Popconfirm
          key={1}
          title="Deseas eliminar?"
          onConfirm={() => handleDelete(subItem.id)}
          cancelText="No"
          okText="Si"
        >
          <Button>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
