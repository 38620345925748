import React from "react";

import "./Footer.css";

export default function Footer({ children }) {
  return (
    <div data-testid="footer" className="footer">
      {children}
    </div>
  );
}
