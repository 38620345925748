import axios from "axios";
import { API_URL } from "../constants";
import { auth, signOut } from "./firebase";

const adminInstance = axios.create({ baseURL: API_URL });

adminInstance.interceptors.request.use(async (config) => {
  if (!auth.currentUser || !sessionStorage.getItem("token")) {
    return config;
  }

  const token = JSON.parse(sessionStorage.getItem("token"));
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

adminInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.response && err.response.status === 403) {
      signOut().finally(() => {
        if (!window.location.pathname.includes("login")) {
          window.location.reload();
        }
      });
    }
    return Promise.reject(err);
  }
);

export default adminInstance;
