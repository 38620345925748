import React, { useEffect, useState } from "react";
import { Button, Image } from "antd";
import { GoogleOutlined } from "@ant-design/icons";
import { signInWithGoogle, auth, signOut } from "../../services/firebase";

import "./LoginScreen.css";
import { useHistory } from "react-router-dom";
import { isAdmin } from "../../services/admin";

const Login = () => {
  const [errorMessage, setErrorMessage] = useState();
  const [isOnLoginProcess, setIsOnLoginProcess] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let isUnmounted = false;
    auth.currentUser ? history.push("/home/users") : history.push("login");
    setIsOnLoginProcess(true);
    setTimeout(() =>
     !isUnmounted && setIsOnLoginProcess(false), 1000);
    return () => (isUnmounted = true);
  }, [auth.currentUser]);

  const signIn = () => {
    setIsOnLoginProcess(true);
    signInWithGoogle().then((result) => {
      const loggedUser = result.user;
      if (loggedUser) {
        const emailRequest = {
          email: loggedUser.email,
        };
        isAdmin(emailRequest)
          .then((isAdminResponse) => {
            if (!isAdminResponse.data) {
              signOut();
            } else {
              loggedUser.getIdToken().then((token) => {
                sessionStorage.setItem("token", JSON.stringify(token));
              });
              setErrorMessage(null);
            }
          })
          .catch((error) => {
            setErrorMessage("No es posible acceder, por favor contacta a soporte");
            console.error(error);
            signOut();
          })
          .finally(() => setIsOnLoginProcess(false));
      }
    });
  };

  return (
    <div className="login-screen">
      <div className="app-logo">
        <Image src="/logo.png" width={200} preview={false} />
      </div>
      <Button loading={isOnLoginProcess} id="login-button" onClick={signIn} size="large" icon={<GoogleOutlined />}>
        Inicia Sesion con Google
      </Button>
      {errorMessage && (
        <div className="error-message">
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};

export default Login;
