import { add, del, edit, getById } from "./commonRequests";

const FOLDER_PATH = "/folder";

export const addFolder = (folder) => {
  return add(FOLDER_PATH, folder);
};

export const deleteFolder = (id) => {
  return del(FOLDER_PATH, id);
};

export const existUserFolderWithFolderId = (id) => {
  return getById(`${FOLDER_PATH}_userFolder`, id);
};

export const getAttributeIdsFromExistingFolder = (id) => {
  return getById(`${FOLDER_PATH}_attributeFolder`, id);
};

export const editFolder = (folder) => {
  return edit(FOLDER_PATH, folder);
};
