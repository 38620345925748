import { add, del, getAll } from "./commonRequests";

const NEWS_PATH = "/news";

export const addNews = (news) => {
  return add(NEWS_PATH, news);
};

export const getAllNews = () => {
  return getAll(NEWS_PATH);
};

export const deleteNews = (id) => {
  return del(NEWS_PATH, id);
};
